import { UpdateTaskRequest } from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { TaskKey } from "src/state/TaskManagementBloc/TaskManagementBloc";
import type { TrackEvent } from "src/state/Track/TrackCubit";
import { patientPCPState, taskManagementState, tracker } from "src/state/state";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import PCPForm from "../../PCPForm/PCPForm";
import { AppQueryPopupsController } from "../AppQueryPopupsBloc";
import styled from "@emotion/styled";
import translate from "src/lib/translate";

const FormLoading = styled.div`
  transition: opacity 0.3s ease-in-out;
  &[data-loading="true"] {
    opacity: 0.3;
    pointer-events: none;
  }
`;

const SelectPcp: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [suspendForm, setSuspendForm] = React.useState(false);

  const onClose = () => {
    AppQueryPopupsController.closePopup();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const handleSaved = () => {
    tracker.track("PCP Information Added/Updated" as TrackEvent);
    AppQueryPopupsController.closePopup();
    void patientPCPState.loadPCP();
  };

  const handleSkip = () => {
    tracker.track("PCP Information Skipped" as TrackEvent);
    setSuspendForm(true);
    void taskManagementState
      .updateTaskStatus(
        {
          program: KnownProgram.ONBOARDING,
          group: "PCP",
          slug: TaskKey.SELECT_PCP
        },
        UpdateTaskRequest.status.SKIPPED
      )
      .then(() => {
        AppQueryPopupsController.closePopup();
      });
  };

  return (
    <InDialog
      popup
      title={translate("yourPcp")}
      onClose={onClose}
      returnUrl={returnUrl}
    >
      <FormLoading data-loading={suspendForm}>
        <PCPForm onPCPSaved={handleSaved} onSkip={handleSkip} />
      </FormLoading>
    </InDialog>
  );
};

export default SelectPcp;
